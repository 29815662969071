<!-- 学校应用统计-上课、投影次数环比 -->
<template>
    <div class="total-wrap flex-align-between">
        <div class="course-count">
            <div class="course_chart" id="course_chart"></div>
        </div>
        <div class="video-count">
            <div class="video_chart" id="video_chart"></div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        termKey: {
            type: Number
        }
    },
    data() {
        return {

        }
    },
    watch: {
        termKey(newVal, oldVal) {
            this.courseCount();
            this.videoCount()
        }
    },
    methods: {
        // 上课次数环比
        courseCount() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termKey
            };
            this.$Api.Report.courseCount(data)
                .then(res => {
                    // console.log('上课次数环比:', res);
                    let legend = [res.data.currentTermDesc, res.data.lastTermDesc];
                    let xAxisData = [];
                    let currentSeriesData = [];
                    let lastSeriesData = [];
                    res.data.currentTerm.forEach(item => {
                        let month = Object.keys(item)[0];
                        let count = item[month];
                        xAxisData.push(month);
                        currentSeriesData.push(count);
                    });
                    res.data.lastTerm.forEach(item => {
                        let month = Object.keys(item)[0];
                        let count = item[month];
                        lastSeriesData.push(count);
                    });
                    this.initCourseChart(legend, xAxisData, currentSeriesData, lastSeriesData)
                })
                .catch(err => {
                    // console.log('上课次数环比err:', err)
                })
        },
        // 投影次数环比
        videoCount() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termKey
            };
            this.$Api.Report.videoCount(data)
                .then(res => {
                    // console.log('投影次数环比:', res);
                    let legend = [res.data.currentTermDesc, res.data.lastTermDesc];
                    let xAxisData = [];
                    let currentSeriesData = [];
                    let lastSeriesData = [];
                    res.data.currentTerm.forEach(item => {
                        let month = Object.keys(item)[0];
                        let count = item[month];
                        xAxisData.push(month);
                        currentSeriesData.push(count);
                    });
                    res.data.lastTerm.forEach(item => {
                        let month = Object.keys(item)[0];
                        let count = item[month];
                        lastSeriesData.push(count);
                    });
                    this.initVideoChart(legend, xAxisData, currentSeriesData, lastSeriesData)
                })
                .catch(err => {
                    console.log('投影次数环比err:', err)
                })
        },
        // 初始化上课次数柱形图
        initCourseChart(legend, xAxisData, currentSeriesData, lastSeriesData) {
            this.$echarts.init(document.getElementById("course_chart")).dispose();
            let myChart = this.$echarts.init(document.getElementById("course_chart"));
            let option = {
                title: {
                    text: "上课次数环比",
                    textStyle: {
                        fontSize: this.graphicRatio(14),
                    },
                    top: 0,
                    left: 'center',
                },
                legend: {
                    show: true,
                    bottom: 0,
                    itemWidth: this.graphicRatio(22),
                    itemHeight: this.graphicRatio(14),
                    textStyle: {
                        color: '#C2C2C2',
                        fontSize: this.graphicRatio(12),
                    }
                },
                tooltip: {
                    show: true,
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    },
                },
                grid: [{
                    show: false,
                    top: '13%',
                    bottom: '8%',
                    height: '35%'
                },
                {
                    show: false,
                    top: '51%',
                    bottom: '8%',
                    height: '0%'
                },
                {
                    show: false,
                    bottom: '8%',
                    containLabel: false,
                     height: '35%'
                }
                ],
                xAxis: [{
                    type: 'category',
                    data: xAxisData,
                    axisTick: {
                        show: false,
                        alignWithLabel: true
                    },
                    splitLine: {
                        show: false
                    },
                    axisLabel: {
                        margin: this.graphicRatio(12),
                        fontSize: this.graphicRatio(14),
                    },
                    axisLine: { // 设置 X 轴线的样式
                        show: true, // 是否显示 X 轴线
                        lineStyle: {
                            color: '#CECECE', // X 轴线的颜色
                        }
                    },
                },
                {
                    type: 'category',
                    gridIndex: 1,
                    data: [],
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#CECECE', // X 轴线的颜色
                        }
                    },
                    axisTick: {
                        show: false,
                        alignWithLabel: true
                    },
                    axisLabel: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    }
                },

                {
                    data: xAxisData,
                    gridIndex: 2,
                    type: 'category',
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#CECECE', // X 轴线的颜色
                        }
                    },
                    axisTick: {
                        show: false,
                        inside: true,
                        alignWithLabel: true
                    },
                    axisLabel: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    }
                }
                ],
                yAxis: [{
                    type: 'value',
                    // 整条y轴
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#CECECE', // 轴线的颜色
                        }
                    },
                    // y轴上的小横线
                    axisTick: {
                        show: false // 隐藏刻度线
                    },
                    splitLine: {
                        show: false,

                    },
                    axisLabel: {
                        fontSize: this.graphicRatio(14),
                    },
                },
                {
                    gridIndex: 1,
                    type: 'value',
                    inverse: true,
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#CECECE', // X 轴线的颜色
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    }
                },
                {
                    gridIndex: 2,
                    type: 'value',
                    inverse: true,
                    // 整条y轴
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#CECECE', // X 轴线的颜色
                        }
                    },
                    // y轴上的小横线
                    axisTick: {
                        show: false // 隐藏 X 轴的刻度线
                    },
                    splitLine: {
                        show: false
                    },
                    axisLabel: {
                        fontSize: this.graphicRatio(14),
                    },
                }
                ],
                series: [{
                    name: legend[0],
                    type: 'bar',
                    barWidth: this.graphicRatio(25),
                    stack: '1',
                    data: currentSeriesData,
                    itemStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0,
                                color: 'rgba(26, 138, 232, 1)' // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: 'rgba(98, 230, 238, 1)' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        },
                        borderRadius: [this.graphicRatio(5), this.graphicRatio(5), 0, 0]
                    },
                    label: {
                        show: true,
                        position: 'top',
                        color: "#CECECE",
                        fontSize: this.graphicRatio(14),
                    }
                },
                {
                    name: '',
                    type: 'bar',
                    barWidth: this.graphicRatio(25),
                    stack: '1',
                    data: []
                },
                {
                    name: legend[1],
                    type: 'bar',
                    xAxisIndex: 2,
                    yAxisIndex: 2,
                    barWidth: this.graphicRatio(25),
                    stack: '2',
                    data: lastSeriesData,
                    itemStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0,
                                color: 'rgba(236, 237, 112, 1)' // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: 'rgba(1, 181, 140, 1)' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        },
                        borderRadius: [0, 0, this.graphicRatio(5), this.graphicRatio(5)]
                    },
                    label: {
                        show: true,
                        position: 'bottom',
                        color: "#CECECE",
                        fontSize: this.graphicRatio(14),
                    }
                }
                ]
            };
            myChart.setOption(option)
        },
        // 初始化投影次数环比
        initVideoChart(legend, xAxisData, currentSeriesData, lastSeriesData) {
            this.$echarts.init(document.getElementById("video_chart")).dispose();
            let myChart = this.$echarts.init(document.getElementById("video_chart"));
            let option = {
                title: {
                    text: "投影次数环比",
                    textStyle: {
                        fontSize: this.graphicRatio(14),
                    },
                    top: 0,
                    left: 'center',
                },
                tooltip: {
                    show: true,
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    },
                },
                legend: {
                    show: true,
                    bottom: 0,
                    itemWidth: this.graphicRatio(22),
                    itemHeight: this.graphicRatio(14),
                    textStyle: {
                        color: '#C2C2C2',
                        fontSize: this.graphicRatio(12),
                    }
                },
                grid: [{
                    show: false,
                    top: '13%',
                    bottom: '8%',
                     height: '35%'
                },
                {
                    show: false,
                    top: '51%',
                    bottom: '8%',
                    height: '0%'
                },
                {
                    show: false,
                    bottom: '8%',
                    containLabel: false,
                     height: '35%'
                }
                ],
                xAxis: [{
                    type: 'category',
                    data: xAxisData,
                    axisTick: {
                        show: false,
                        alignWithLabel: true
                    },
                    splitLine: {
                        show: false
                    },
                    axisLabel: {
                        margin: this.graphicRatio(12),
                        fontSize: this.graphicRatio(14),
                    },
                    axisLine: { // 设置 X 轴线的样式
                        show: true, // 是否显示 X 轴线
                        lineStyle: {
                            color: '#CECECE', // X 轴线的颜色
                        }
                    },
                },
                {
                    type: 'category',
                    gridIndex: 1,
                    data: [],
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#CECECE', // X 轴线的颜色
                        }
                    },
                    axisTick: {
                        show: false,
                        alignWithLabel: true
                    },
                    axisLabel: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    }
                },

                {
                    data: xAxisData,
                    gridIndex: 2,
                    type: 'category',
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#CECECE', // X 轴线的颜色
                        }
                    },
                    axisTick: {
                        show: false,
                        inside: true,
                        alignWithLabel: true
                    },
                    axisLabel: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    }
                }
                ],
                yAxis: [{
                    type: 'value',
                    // 整条y轴
                    interval: 10,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#CECECE', // 轴线的颜色
                        }
                    },
                    axisTick: {
                        show: false // 隐藏刻度线
                    },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        fontSize: this.graphicRatio(14),
                    },
                },
                {
                    gridIndex: 1,
                    type: 'value',
                    interval: 10,
                    inverse: true,
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#CECECE', // X 轴线的颜色
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    splitLine: {
                        show: false
                    }
                },
                {
                    gridIndex: 2,
                    type: 'value',
                    interval: 10,
                    inverse: true,
                    // 整条y轴
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#CECECE', // X 轴线的颜色
                        }
                    },
                    // y轴上的小横线
                    axisTick: {
                        show: false // 隐藏 X 轴的刻度线
                    },
                    splitLine: {
                        show: false
                    },
                    axisLabel: {
                        fontSize: this.graphicRatio(14),
                    },
                }
                ],
                series: [{
                    name: legend[0],
                    type: 'bar',
                    barWidth: this.graphicRatio(25),
                    stack: '1',
                    data: currentSeriesData,
                    itemStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0,
                                color: 'rgba(176, 98, 242, 1)' // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: 'rgba(228, 153, 222, 1)' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        },
                        borderRadius: [this.graphicRatio(5), this.graphicRatio(5), 0, 0]
                    },
                    label: {
                        show: true,
                        position: 'top',
                        color: "#CECECE",
                        fontSize: this.graphicRatio(14),
                    }
                },
                {
                    name: '',
                    type: 'bar',
                    barWidth: this.graphicRatio(25),
                    stack: '1',
                    data: []
                },
                {
                    name: legend[1],
                    type: 'bar',
                    xAxisIndex: 2,
                    yAxisIndex: 2,
                    barWidth: this.graphicRatio(25),
                    stack: '2',
                    data: lastSeriesData,
                    itemStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0,
                                color: 'rgba(250, 195, 141, 1)' // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: 'rgba(254, 104, 143, 1)' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        },
                        borderRadius: [0, 0, this.graphicRatio(5), this.graphicRatio(5)]
                    },
                    label: {
                        show: true,
                        position: 'bottom',
                        color: "#CECECE",
                        fontSize: this.graphicRatio(14),
                    }
                }
                ]
            };
            myChart.setOption(option)
        }
    },
    mounted() {
        // this.courseCount();
        // this.videoCount();
    },
};
</script>
<style lang='less' scoped>
.total-wrap {
    width: 100%;
    height: 100%;

    .course-count,
    .video-count {
        width: 680px;
        height: 100%;


        .course_chart,
        .video_chart {
            width: 100%;
            height: 100%;
        }
    }
}
</style>