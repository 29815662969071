<!-- App学习统计折线图 -->
<template>
    <div class="appStudy_line" id="appStudy_line"></div>
</template>
<script>
export default {
    props: {
        studyData: {
            type: Array,
            require: true
        }
    },
    data() {
        return {}
    },
    watch: {
        studyData() {
            this.initChart()
        }
    },
    methods: {
        initChart() {
            let xAxisData = [];
            let seriesData = [];
            this.studyData.map(item => {
                xAxisData.push(item.name);
                seriesData.push(item.num)
            })
            this.$echarts.init(document.getElementById("appStudy_line")).dispose();
            let myChart = this.$echarts.init(document.getElementById("appStudy_line"));
            let option = {
                title: {
                    text: "各年级学生APP学习统计",
                    textStyle: {
                        fontSize: this.graphicRatio(14),
                    },
                    top: 0,
                    left: 'center',
                },
                tooltip: {
                    show: true,
                },
                legend: {
                    show: true,
                    bottom: 0,
                    textStyle: {
                        color: '#C2C2C2',
                        fontSize:this.graphicRatio(12),
                    }
                },
                xAxis: {
                    type: 'category',
                    data: xAxisData,
                    axisTick: {
                        show: false // 隐藏 X 轴的刻度线
                    },
                    axisLine: { // 设置 X 轴线的样式
                        show: true, // 是否显示 X 轴线
                        lineStyle: {
                            color: '#CECECE', // X 轴线的颜色
                        }
                    },
                    axisLabel: {
                        fontSize: this.graphicRatio(14),
                    },
                },
                yAxis: {
                    type: 'value',
                    axisTick: {
                        show: false // 隐藏 Y 轴的刻度线
                    },
                    axisLine: { // 设置 Y 轴线的样式
                        show: true, // 是否显示 Y 轴线
                        lineStyle: {
                            color: '#CECECE', // Y 轴线的颜色
                        }
                    },
                    axisLabel: {
                        fontSize: this.graphicRatio(14),
                    },
                },
                series: [{
                    name: '学习课程(节)',
                    data: seriesData,
                    type: 'line',
                    smooth: true,
                    itemStyle: {
                        color: "#55CF1F"
                    },
                    label: {
                        show: true,
                        position: 'top',
                        color: "#CECECE",
                        fontSize: this.graphicRatio(14),
                    }
                }]
            };
            myChart.setOption(option)
        }
    },
    mounted() { },
};
</script>
<style lang='less' scoped>
.appStudy_line {
    width: 100%;
    height: 100%;
}
</style>