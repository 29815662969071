<!-- 观看最多的课程Top10 柱状图 -->
<template>
    <div class="mostWatch-bar" id="mostWatch_bar"></div>
</template>
<script>
export default {
    props: {
        mostData: {
            type: Array,
            require: true
        }
    },
    data() {
        return {}
    },
    watch: {
        mostData() {
            this.initChart()
        }

    },
    methods: {
        initChart() {
            let yAxisData = [];
            let seriesData = [];
            this.mostData.map(item => {
                yAxisData.unshift(item.title);
                seriesData.unshift(item.num)
            })
            this.$echarts.init(document.getElementById("mostWatch_bar")).dispose();
            let myChart = this.$echarts.init(document.getElementById("mostWatch_bar"));
            let option = {
                title: {
                    left: 'center',
                    text: '观看最多的课程Top10',
                    textStyle: {
                        fontSize: this.graphicRatio(14),
                    },
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    },
                },
                grid: {
                    left: '3%',
                    right: this.graphicRatio(10),
                    bottom: 0,
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01],
                    axisTick: {
                        show: false // 隐藏 X 轴的刻度线
                    },
                    axisLine: { // 设置 X 轴线的样式
                        show: true, // 是否显示 X 轴线
                        lineStyle: {
                            color: '#CECECE', // X 轴线的颜色
                        }
                    },
                    axisLabel: {
                        fontSize: this.graphicRatio(14),
                    },
                },
                yAxis: {
                    type: 'category',
                    data: yAxisData,
                    axisTick: {
                        show: false // 隐藏 Y 轴的刻度线
                    },
                    axisLine: { // 设置 Y 轴线的样式
                        show: true, // 是否显示 Y 轴线
                        lineStyle: {
                            color: '#CECECE', // Y 轴线的颜色
                        }
                    },
                    axisLabel: {
                        fontSize: this.graphicRatio(14),
                    },
                },
                series: [{
                    type: 'bar',
                    data: seriesData,
                    barWidth: this.graphicRatio(10),
                    itemStyle: {
                        color: '#E89756'
                    }
                }]
            };
            myChart.setOption(option)
        },

    },
    mounted() {
        this.initChart();
    },
};
</script>
<style lang='less' scoped>
.mostWatch-bar {
    width: 100%;
    height: 100%;
}
</style>