<!-- 本校上课时间分布 饼图 -->
<template>
    <div class="courseTime_pie" id="courseTime_pie"></div>
</template>
<script>
export default {
    props: {
        termKey: {
            type: Number
        }
    },
    data() {
        return {}
    },
    watch: {
        termKey(newVal, oldVal) {
            this.courseTimeDataPie()
        }
    },
    methods: {
        initChart(seriesData) {
            this.$echarts.init(document.getElementById("courseTime_pie")).dispose();
            let myChart = this.$echarts.init(document.getElementById("courseTime_pie"));
            let option = {
                title: {
                    text: "本校上课类型分布",
                    textStyle: {
                        fontSize: this.graphicRatio(14),
                    },
                    top: 0,
                    left: 'center',
                },
                tooltip: {
                    show: true,
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    },
                },
                grid: {
                    top: 0
                },
                legend: {
                    top: 'bottom',
                    itemWidth: this.graphicRatio(22),
                    itemHeight: this.graphicRatio(14),
                    textStyle: {
                        color: '#C2C2C2',
                        fontSize: this.graphicRatio(12),
                    }
                },
                color: ['#A859FF', '#FF9745', '#34ABFF', '#6B89FF'],
                series: [{
                    name: '本校上课时间分布',
                    type: 'pie',
                    radius: '60%',
                    center: ['50%', '50%'],
                    roseType: 'radius',
                    itemStyle: {
                        borderRadius: 0
                    },
                    data: seriesData,
                    label: {
                        // 在这里设置 label 的显示内容，使用 {b} 表示标签名（name），{c} 表示数值（value）
                        formatter: '{b}: {c}',
                        color:'#C2C2C2',
                        fontSize: this.graphicRatio(12),
                    }
                }]
            };
            myChart.setOption(option)
        },
        courseTimeDataPie() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termKey
            };
            this.$Api.Report.courseTimeDataPie(data)
                .then(res => {
                    // console.log('本校上课时间分布饼图:', res);
                    let seriesData = [];
                    res.data.map(item => {
                        let obj = {
                            value: item.num,
                            name: item.name
                        }
                        seriesData.push(obj)
                    })
                    this.initChart(seriesData)
                })
                .catch(err => {
                    // console.log('本校上课时间分布饼图err:', err)
                })
        }
    },
    mounted() {

    },
};
</script>
<style lang='less' scoped>
.courseTime_pie {
    width: 100%;
    height: 100%;
}
</style>