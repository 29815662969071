import { render, staticRenderFns } from "./gradeCourseTotal.vue?vue&type=template&id=47d05a7b&scoped=true&"
import script from "./gradeCourseTotal.vue?vue&type=script&lang=js&"
export * from "./gradeCourseTotal.vue?vue&type=script&lang=js&"
import style0 from "./gradeCourseTotal.vue?vue&type=style&index=0&id=47d05a7b&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47d05a7b",
  null
  
)

export default component.exports