<!-- 本校上课时间分布折线图 -->
<template>
    <div class="rbbox">
        <div v-if="showChart" class="systemRbCourse_line" id="systemRbCourse_line"></div>
        <div class="empty flex-column-center" v-else>
            <!-- <span>特色课应用情况</span> -->
            <el-empty description="暂无数据" ></el-empty>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        termKey: {
            type: Number
        },
        grade: {
            type: [Number, String],
            default: ''
        }
    },
    data() {
        return {
            showChart: true
        }
    },
    watch: {
        termKey(newVal, oldVal) {
            this.rbData()
        },
        grade(newVal, oldVal) {
            this.rbData()
        }
    },
    methods: {
        initRbCharts(xAxisData, seriesData) {
            let legendData = [];
            for (let i = 0; i < seriesData.length; i++) {
                legendData.push(seriesData[i].name)
            }
            this.$echarts.init(document.getElementById("systemRbCourse_line")).dispose();
            let myChart = this.$echarts.init(document.getElementById("systemRbCourse_line"));
            let option = {
                tooltip: {
                    show: true,
                    trigger: 'axis',
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    },
                },
                grid: {
                    left: 0,
                    right: 15,
                    bottom: 0, // 调整底部距离的数值，可以根据需要进行调整
                    containLabel: true
                },
                title: {
                    text: "软笔体系课",
                    textStyle: {
                        fontSize: this.graphicRatio(14),
                    },
                    top: 0,
                    left: 'center',
                },
                color: ['#A856D3', '#7ECEF4', '#34CAAC', '#78C35E', '#EA68A2', '#FFD040',],
                legend: {
                    data: legendData,
                    // data: ['一年级', '二年级', '三年级', '四年级', '五年级', '六年级'],
                    top: this.graphicRatio(30),
                    textStyle: {
                        color: '#C2C2C2',
                        fontSize: this.graphicRatio(12),
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xAxisData,
                    axisTick: {
                        show: false // 隐藏 X 轴的刻度线
                    },
                    axisLine: { // 设置 X 轴线的样式
                        show: true, // 是否显示 X 轴线
                        lineStyle: {
                            color: '#CECECE', // X 轴线的颜色
                        }
                    },
                    axisLabel: {
                        fontSize: this.graphicRatio(14),
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLine: { // 设置 Y 轴线的样式
                        show: true, // 是否显示 Y 轴线
                        lineStyle: {
                            color: '#CECECE', // Y 轴线的颜色
                        }
                    },
                    axisLabel: {
                        fontSize: this.graphicRatio(14),
                    },
                },
                series: seriesData
            };
            myChart.setOption(option)
        },
        rbData() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termKey,
                grade: this.grade,
                actionType: 2
            };
            this.$Api.Report.systemCourse(data)
                .then(res => {
                    // console.log('软笔体系课:', res);
                    let seriesData = [];
                    let keys = Object.keys(res.data);
                    res.data[keys[0]].map(item => {
                        let obj = {
                            name: item.name,
                            type: 'line',
                            data: [],
                            itemStyle: {
                                opacity: 0,
                            },
                        }
                        seriesData.push(obj)
                    })

                    keys.map(item => {
                        res.data[item].map(items => {
                            switch (items.name) {
                                case '一年级':
                                    var index = seriesData.findIndex(item => item.name ==
                                        '一年级');
                                    seriesData[index].data.push(items.num)
                                    break;
                                case '二年级':
                                    var index = seriesData.findIndex(item => item.name ==
                                        '二年级');
                                    seriesData[index].data.push(items.num)
                                    break;
                                case '三年级':
                                    var index = seriesData.findIndex(item => item.name ==
                                        '三年级');
                                    seriesData[index].data.push(items.num)
                                    break;
                                case '四年级':
                                    var index = seriesData.findIndex(item => item.name ==
                                        '四年级');
                                    seriesData[index].data.push(items.num)
                                    break;
                                case '五年级':
                                    var index = seriesData.findIndex(item => item.name ==
                                        '五年级');
                                    seriesData[index].data.push(items.num)
                                    break;
                                case '六年级':
                                    var index = seriesData.findIndex(item => item.name ==
                                        '六年级');
                                    seriesData[index].data.push(items.num)
                                    break;
                            }
                        })
                    })
                    // console.log('keys:', keys)
                    // console.log('seriesData:', seriesData)
                    this.initRbCharts(keys, seriesData)
                })
                .catch(err => {
                    // console.log('硬笔体系课err:', err);
                    let keys = ['1周', '2周', '3周', '4周', '5周', '6周', '7周', '8周', '9周', '10周', '11周', '12周', '13周', '14周', '15周', '16周', '17周', '18周', '19周', '20周', '21周', '22周', '23周', '24周', '25周', '26周'];
                    let seriesData = [
                        {
                            data: [],
                            itemStyle: { opcaity: 0 },
                            name: '',
                            type: "line"
                        }
                    ];
                    this.initRbCharts(keys, seriesData)
                })

        }
    },
    mounted() {

    },
};
</script>
<style lang='less' scoped>
.rbbox {
    width: 100%;
    height: 100%;

    .systemRbCourse_line {
        width: 100%;
        height: 100%;
    }
    .empty{
        span{
            font-size: 14px;
            font-weight: bold;
        }
    }
}
</style>