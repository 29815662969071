<!-- App测评次数柱状图 -->
<template>
    <div class="appTest_bar" id="appTest_bar"></div>
</template>
<script>
export default {
    props: {
        testData: {
            type: Array,
            require: true
        }
    },
    data() {
        return {}
    },
    watch: {
        testData() {
            this.initChart()
        }
    },
    methods: {
        initChart() {
            let xAxisData = [];
            let seriesData = [];
            this.testData.map(item => {
                xAxisData.push(item.name);
                seriesData.push(item.num)
            })
            this.$echarts.init(document.getElementById("appTest_bar")).dispose();
            let myChart = this.$echarts.init(document.getElementById("appTest_bar"));
            let option = {
                tooltip: {
                    show: true,
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    },
                },
                legend: {
                    show: true,
                    bottom: 0,
                    itemWidth: this.graphicRatio(22),
                    itemHeight: this.graphicRatio(14),
                    textStyle: {
                        color: '#C2C2C2',
                        fontSize: this.graphicRatio(12),
                    }
                },
                grid: {
                    right: 0,
                    left: 0,
                    bottom: this.graphicRatio(30), // 调整底部距离的数值，可以根据需要进行调整
                    containLabel: true
                },
                title: {
                    text: "各年级学生APP测评次数统计",
                    textStyle: {
                        fontSize: this.graphicRatio(14),
                    },
                    top: 0,
                    left: 'center',

                },
                xAxis: {
                    type: 'category',
                    data: xAxisData,
                    axisTick: {
                        show: false // 隐藏 X 轴的刻度线
                    },
                    axisLine: { // 设置 X 轴线的样式
                        show: true, // 是否显示 X 轴线
                        lineStyle: {
                            color: '#CECECE', // X 轴线的颜色
                        }
                    },
                    axisLabel: {
                        fontSize: this.graphicRatio(14),
                    },
                },
                yAxis: {
                    type: 'value',
                    splitLine: {
                        show: false // 不显示横向网格线
                    },
                    axisLine: { // 设置 Y 轴线的样式
                        show: true, // 是否显示 Y 轴线
                        lineStyle: {
                            color: '#CECECE', // Y 轴线的颜色
                        }
                    },
                    axisLabel: {
                        fontSize: this.graphicRatio(14),
                    },
                },
                series: [{
                    name: '上传作品(个)',
                    data: seriesData,
                    type: 'bar',
                    barWidth: this.graphicRatio(32),
                    itemStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0,
                                color: 'rgba(167, 198, 255, 1)' // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: 'rgba(203, 156, 252, 1)' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        },
                        borderRadius: [this.graphicRatio(5), this.graphicRatio(5), 0, 0]
                    },
                    label: {
                        show: true, 
                        position: 'top',
                        color: "#CECECE",
                        fontSize: this.graphicRatio(14),
                    }
                }]
            };
            myChart.setOption(option)
        }
    },
    mounted() {
    },
};
</script>
<style lang='less' scoped>
.appTest_bar {
    widows: 100%;
    height: 100%;
}
</style>