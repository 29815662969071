<!-- 本校上课时间分布 横向柱状图 -->
<template>
    <div class="courseTime_bar" id="courseTime_bar"></div>
</template>
<script>
export default {
    props: {
        termKey: {
            type: Number
        }
    },
    data() {
        return {}
    },
    watch: {
        termKey(newVal, oldVal) {
            this.courseTimeDataBar()
        }
    },
    methods: {
        initChart(seriesData) {
            this.$echarts.init(document.getElementById("courseTime_bar")).dispose();
            let myChart = this.$echarts.init(document.getElementById("courseTime_bar"));
            let option = {
                title: {
                    text: '各年级上课类型分布',
                    textStyle: {
                        fontSize: this.graphicRatio(14),
                    },
                    top: 0,
                    left: 'center',
                },
                tooltip: {
                    show: true,
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    },
                },
                legend: {
                    top: 'bottom',
                    itemWidth: this.graphicRatio(22),
                    itemHeight: this.graphicRatio(14),
                    textStyle: {
                        color: '#C2C2C2',
                        fontSize: this.graphicRatio(12),
                    }
                },
                color: ['#8FB6FC', '#E89756', '#FDCA17', '#78C35E', '#A859FF'],
                grid: {
                    top: this.graphicRatio(40),
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01],
                    axisTick: {
                        show: false // 隐藏 X 轴的刻度线
                    },
                    axisLine: { // 设置 X 轴线的样式
                        show: true, // 是否显示 X 轴线
                        lineStyle: {
                            color: '#CECECE', // X 轴线的颜色
                        }
                    },
                    axisLabel: {
                        fontSize: this.graphicRatio(14),  
                    }
                },
                yAxis: {
                    type: 'category',
                    data: ['一年级', '二年级', '三年级', '四年级', '五年级', '六年级'],
                    axisTick: {
                        show: false // 隐藏 Y 轴的刻度线
                    },
                    axisLine: { // 设置 Y 轴线的样式
                        show: true, // 是否显示 Y 轴线
                        lineStyle: {
                            color: '#CECECE', // Y 轴线的颜色
                        }
                    },
                    axisLabel: {
                        fontSize: this.graphicRatio(14),  
                    }
                },
                series: seriesData
            };
            myChart.setOption(option)
        },
        courseTimeDataBar() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termKey
            };
            this.$Api.Report.courseTimeDataBar(data)
                .then(res => {
                    // console.log('本校上课时间分布柱状图:', res);
                    const keys = Object.keys(res.data);
                    const seriesData = [];
                    for (let key of keys) {
                        let obj = {
                            name: key,
                            type: 'bar',
                            data: res.data[key],
                            barGap: 0,
                            label: {
                                show: true,
                                position: "right",
                                color: '#C2C2C2',
                                fontSize: this.graphicRatio(12),
                            }
                        }
                        seriesData.push(obj)
                    }
                    // console.log('seriesData:',seriesData)
                    this.initChart(seriesData)
                })
                .catch(err => {
                    // console.log('本校上课时间分布柱状图err:', err)
                })
        }
    },
    mounted() {

    },
};
</script>
<style lang='less' scoped>
.courseTime_bar {
    width: 100%;
    height: 100%;
}
</style>