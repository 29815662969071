<!-- 报告2.3 -->
<template>
    <div class="app-box">
        <div class="export flex-align">
            <el-select v-model="allDateTerm" placeholder="请选择学期" :popper-append-to-body="false" @change="termChangeAllDate">
                <el-option v-for="item in termList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
            <div class="wrap flex-align" @click="exportReport">
                <img src="../../assets/newReport/export.png" alt="">
                导出
            </div>
        </div>
        <div class="all-box">
            <!-- 整体应用情况 -->
            <div class="all-info">
                <div class="top flex-align-between">
                    <div class="title flex-align">
                        <div class="line"></div>
                        整体应用情况
                    </div>
                    <div class="search flex-align">
                        <el-date-picker v-model="allDate" type="daterange" range-separator="至" start-placeholder="开始日期"
                            end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss">
                        </el-date-picker>
                        <div class="search-btn flex-align-around" @click="searchByAllData">
                            <div>
                                <img src="../../assets/newReport/search.png" alt="">
                                查询
                            </div>
                        </div>
                    </div>
                </div>
                <div class="total-info">
                    <span class="course">平台总上课<span class="count">{{ courseCount }}</span>次</span>
                    <span>同步投影总量<span class="count">{{ videoCount }}</span>个(作品投影+视频投影)</span>
                </div>
                <div class="content flex-align">
                    <div class="course-total">
                        <GradeCourseTotal name="GradeCourseTotal" :GradeCourseTotalData="GradeCourseTotalData"
                            v-if="GradeCourseTotalData.length != 0">
                        </GradeCourseTotal>
                        <div class="empty flex-column-center" v-else>
                            <span>各年级上课统计</span>
                            <el-empty description="暂无数据"></el-empty>
                        </div>
                    </div>
                    <div class="tea-rank flex-column-center">
                        <div class="rank-title flex-align">本校教师上课排名<span>(滚动)</span></div>
                        <div class="rank-list">
                            <div class="title flex-align">
                                <span class="title-item flex-align-around">排名</span>
                                <span class="title-item flex-align-around">姓名</span>
                                <span class="title-item flex-align-around">上课总次数</span>
                            </div>
                            <template v-if="teaRankList.length > 0">
                                <div class="rank-item flex-align-around" v-for="(item, index) in teaRankList" :key="index">
                                    <span class="item flex-align-around">
                                        <img src="../../assets/newReport/first.png" alt="" v-if="index == 0">
                                        <img src="../../assets/newReport/second.png" alt="" v-if="index == 1">
                                        <img src="../../assets/newReport/third.png" alt="" v-if="index == 2">
                                        <span v-if="index > 2">{{ index + 1 }}</span>
                                    </span>
                                    <span class="item flex-align-around">{{ item.name }}</span>
                                    <span class="item flex-align-around">{{ item.num }}</span>
                                </div>
                            </template>
                            <el-empty description="暂无数据" v-else></el-empty>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 学校应用统计 -->
            <div class="school-info">
                <div class="title flex-align">
                    <div class="line"></div>
                    学校应用统计
                </div>
                <div class="chart-wrap">
                    <applicationTotalBar :termKey="allDateTerm"></applicationTotalBar>
                </div>
            </div>
            <!-- 最新应用动态 -->
            <div class="state-info">
                <div class="title flex-align">
                    <div class="line"></div>
                    最新应用动态
                </div>
                <div class="application-data">
                    <GradeCourseTotal name="stateInfo"></GradeCourseTotal>
                </div>
                <div class="grade-info">
                    <div class="grade-title flex-align-around">
                        <div class="flex-align">
                            各年级应用对比
                            <span>(上课次数+投影次数)</span>
                        </div>
                    </div>
                    <div class="grade-content">
                        <div class="grade-head flex-align-around">
                            <span class="flex-align-around" v-for="(item, index) in gradeList" :key="index">{{ item
                            }}</span>
                        </div>
                        <div class="grade-item flex-align-around" v-for="(item, index) in Object.keys(gradeApplicationList)"
                            :key="index">
                            <span class="flex-align-around">{{ item }}</span>
                            <span class="flex-align-around" v-for="(items, indexs) in gradeApplicationList[item]"
                                :key="indexs">{{ items.num }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 本学期上课时间与类型分布情况 -->
            <div class="term-info">
                <div class="top flex-align-between">
                    <div class="title flex-align">
                        <div class="line"></div>
                        本学期上课时间与类型分布情况
                    </div>
                </div>
                <div class="line-wrap">
                    <courseTime :termKey="allDateTerm"></courseTime>
                </div>
                <div class="time-wrap flex-align">
                    <div class="pie-wrap">
                        <courseTimePie :termKey="allDateTerm"></courseTimePie>
                    </div>
                    <div class="bar-wrap">
                        <courseTimeBar :termKey="allDateTerm"></courseTimeBar>
                    </div>
                </div>
            </div>
            <!-- 同步课达成率分析 -->
            <div class="async-info">
                <div class="top flex-align-between">
                    <div class="title flex-align">
                        <div class="line"></div>
                        同步课达成率分析
                    </div>
                </div>
                <span class="tips">*本学期同步课达成率=上课字数 / 本年级总字数</span>
                <div class="async-wrap">
                    <div class="async-course" v-if="asyncCourseList.length > 0">
                        <div class="course-head flex-align">
                            <span class="flex-align-around" v-for="(item, index) in asyncHeadList" :key="index">{{ item
                            }}</span>
                        </div>
                        <div class="grade-item flex-align-around" v-for="(item, index) in asyncCourseList" :key="index">
                            <span class="flex-align-around">{{ index + 1 }}</span>
                            <span class="flex-align-around">{{ item.className }}</span>
                            <span class="flex-align-around">{{ item.num }}</span>
                            <div class="flex-align-around">
                                <el-progress :text-inside="true" :stroke-width="24" :percentage="item.rate"
                                    text-color="#fff"></el-progress>
                            </div>
                        </div>
                    </div>
                    <div class="empty flex-column-center" v-else>
                        <el-empty description="暂无数据"></el-empty>
                    </div>
                </div>
            </div>
            <!-- 体系课程应用情况 -->
            <div class="system-info">
                <div class="top flex-align-between">
                    <div class="title flex-align">
                        <div class="line"></div>
                        体系课程应用情况
                    </div>
                    <div class="select-box flex-align">
                        <el-select v-model="gradeValue" placeholder="全部年级" :popper-append-to-body="false">
                            <el-option v-for="item in gradeAll" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="tscourse-wrap">
                    <systemCourseBar :termKey="allDateTerm" :grade="gradeValue"></systemCourseBar>
                </div>
                <div class="ybcourse-wrap">
                    <systemYbCourseLine :termKey="allDateTerm" :grade="gradeValue"></systemYbCourseLine>
                </div>
                <div class="rycourse-wrap">
                    <systemRbCourseLine :termKey="allDateTerm" :grade="gradeValue"></systemRbCourseLine>
                </div>
            </div>
            <!-- APP学生使用情况统计 -->
            <div class="app-info">
                <div class="top flex-align-between">
                    <div class="title flex-align">
                        <div class="line"></div>
                        APP学生使用情况统计
                    </div>
                    <div class="search flex-align">
                        <el-date-picker v-model="date" type="daterange" range-separator="至" start-placeholder="开始日期"
                            end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss">
                        </el-date-picker>
                        <div class="search-btn flex-align-around" @click="searchByAppInfo">
                            <div>
                                <img src="../../assets/newReport/search.png" alt="">
                                查询
                            </div>
                        </div>
                    </div>
                </div>
                <div class="middle flex-align-between">
                    <div class="app-rank flex-column-center">
                        <span class="title">学生APP应用学习排名</span>
                        <div class="rank-wrap">
                            <div class="head flex-align-around">
                                <span class="head-item flex-align-around">排名</span>
                                <span class="head-item flex-align-around">班级名称</span>
                                <span class="head-item flex-align-around">学生姓名</span>
                                <span class="head-item flex-align-around">APP学习课程数</span>
                                <span class="head-item flex-align-around">上传作品总数</span>
                            </div>
                            <template v-if="stuRankList.length > 0">
                                <div class="rank-item flex-align-around" v-for="(item, index) in stuRankList" :key="index">
                                    <span class="flex-align-around">{{ item.sort }}</span>
                                    <span class="flex-align-around">{{ item.className }}</span>
                                    <span class="flex-align-around">{{ item.uname }}</span>
                                    <span class="flex-align-around">{{ item.appNum }}</span>
                                    <span class="flex-align-around">{{ item.workNum }}</span>
                                </div>
                            </template>
                            <template v-else>
                                <el-empty description="暂无数据"></el-empty>
                            </template>

                        </div>
                    </div>
                    <div class="most-watch">
                        <mostCourseBar :mostData="mostData" v-if="mostData.length > 0"></mostCourseBar>
                        <div class="empty flex-column-center" v-else>
                            <span>观看最多的课程Top10</span>
                            <el-empty description="暂无数据"></el-empty>
                        </div>
                    </div>
                </div>
                <div class="bottom flex-align-between">
                    <div class="appstudy-wrap">
                        <appStudyLine :studyData="studyData"></appStudyLine>
                    </div>
                    <div class="apptest-wrap">
                        <appTestBar :testData="testData"></appTestBar>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import GradeCourseTotal from './chartsComponents/gradeCourseTotal.vue'; // 各年级上课统计柱状图、最近15天应用数据柱状图
import courseTime from './chartsComponents/courseTime.vue'; // 本校上课时间分布折线图
import courseTimePie from './chartsComponents/courseTime_pie.vue'; // 本校上课时间分布饼图
import courseTimeBar from './chartsComponents/courseTime_bar.vue' // 本校上课时间分布柱状图(横向)
import asyncCourseBar from './chartsComponents/asyncCourse_bar.vue' // 同步课达成率分析柱状图(带基准线)
import systemCourseBar from './chartsComponents/systemCourse_bar.vue'; // 特色课应用情况柱状图
import systemYbCourseLine from './chartsComponents/systemYbCourse_line.vue'; // 硬笔体系课折线图
import systemRbCourseLine from './chartsComponents/systemRbCourse_line.vue'; // 软笔体系课折线图
import mostCourseBar from './chartsComponents/mostCourse_bar.vue'; // 观看最多的课程Top10
import appStudyLine from './chartsComponents/appStudy_line.vue'; // app学习统计折线图
import appTestBar from './chartsComponents/appTest_bar.vue'; // app测评次数柱状图
import applicationTotalBar from './chartsComponents/applicationTotal_bar.vue'; // 上课、投影次数环比柱状图
export default {
    components: {
        GradeCourseTotal,
        courseTime,
        courseTimePie,
        courseTimeBar,
        asyncCourseBar,
        systemCourseBar,
        systemYbCourseLine,
        systemRbCourseLine,
        mostCourseBar,
        appStudyLine,
        appTestBar,
        applicationTotalBar,
    },
    data() {
        return {
            courseCount: 0,
            videoCount: 0,
            allDate: [], // 整体应用情况 开始时间-结束时间
            date: [],
            gradeList: ['月份', '1年级', '2年级', '3年级', '4年级', '5年级', '6年级', '均值'],
            termValue: '',
            termList: [],
            gradeAll: [{
                value: '',
                label: '全部年级'
            },
            {
                value: 1,
                label: '1年级'
            },
            {
                value: 2,
                label: '2年级'
            },
            {
                value: 3,
                label: '3年级'
            },
            {
                value: 4,
                label: '4年级'
            },
            {
                value: 5,
                label: '5年级'
            },
            {
                value: 6,
                label: '6年级'
            },
            ],
            gradeValue: '',
            teaRankList: [],
            stuRankList: [],
            watchMostList: [],
            GradeCourseTotalData: [], // 各年级上课统计柱状图数据
            gradeApplicationList: [], // 各年级应用对比表格
            allDateTerm: null, // 当前学期(整体应用情况)
            // courseTimeTerm: null, // 当前学期(本学期上课时间与类型分布情况)
            // asyncTerm: null, // 当前学期(同步课达成率) 
            // systemTerm: null, // 当前学期(体系课程应用情况)
            mostData: [],
            studyData: [],
            testData: [],
            asyncHeadList: ['序号', '班级', '同步上课字数', '课程达成率'],
            asyncCourseList: [
                { className: '一年级1班', num: 100, rate: 100 }
            ]
        }
    },
    methods: {
        // 整体应用情况数量统计
        countTotal(startTime, endTime) {
            let data = {
                schoolId: this.$route.query.schoolId,
                startTime,
                endTime,
                termKey: this.allDateTerm
            };
            this.$Api.Report.countTotal(data)
                .then(res => {
                    this.courseCount = res.data.numberOfLessons;
                    this.videoCount = res.data.projectedQuantity
                })
                .catch(err => {
                    console.log(err)
                })
        },
        // 导出报告
        exportReport() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.allDateTerm
            };
            this.$Api.Report.export(data)
                .then(res => {
                    console.log('导出成功:', res);
                    const blob = new Blob([res], {
                        type: 'application/octet-stream'
                    });
                    const url = window.URL.createObjectURL(blob);
                    // 以动态创建a标签进行下载
                    const termName = this.termList.find(item => item.value == this.allDateTerm).label
                    const a = document.createElement('a');
                    const fileName = `${this.$route.query.schoolName}教学应用数据报告(${termName})`;
                    a.href = url;
                    a.download = fileName + '.xlsx';
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(err => {
                    console.log('导出失败:', err)
                })
        },
        //获取学年学期列表 this.$Api.DataScreen
        getTermList() {
            this.$Api.DataScreen.queryTermList()
                .then(res => {
                    // console.log('教学应用报告学期列表:', res);
                    res.data.map(item => {
                        let term = {
                            value: item.key,
                            label: item.termName,
                            startTime: item.startTime,
                            endTime: item.endTime
                        }
                        this.termList.push(term)
                    })
                    this.allDateTerm = this.termList[0].value;
                    this.asyncData();
                    this.gradeApplicationData();
                    this.getTermFirstDay();
                })
                .catch(err => {
                    console.log('err:', err)
                })
        },
        // 获取学期第一天
        getTermFirstDay() {
            this.$Api.Report.getTermFirstDay()
                .then(res => {
                    // console.log('学期第一天:', res);
                    let startTime = res.data.startTime;
                    // 获取当前日期
                    const currentDate = new Date();
                    // 获取当前年份
                    const currentYear = currentDate.getFullYear();
                    // 获取当前月份（注意：月份从0开始，所以需要加1）
                    const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
                    // 获取当前日期
                    const currentDay = currentDate.getDate().toString().padStart(2, '0');
                    const today = `${currentYear}-${currentMonth}-${currentDay} 00:00:00`;
                    this.allDate = [startTime, today];
                    this.date = [startTime, today];
                    this.gradeCourseTotal(startTime, today); // 整体-各年级上课统计
                    this.teaRank(startTime, today); // 整体-本校教师上课排名
                    this.countTotal(startTime, today); // 整体-平台总上课次数
                    this.stuAppRank();
                    this.watchMost();
                    this.stuAppStudy();
                    this.stuAppTest();
                })
                .catch(err => {
                    console.log('获取学期第一天失败:', err)
                })
        },
        // 各年级上课统计
        gradeCourseTotal(startTime, endTime) {
            this.GradeCourseTotalData = [];
            let data = {
                schoolId: this.$route.query.schoolId,
                startTime,
                endTime,
                termKey: this.allDateTerm
            };
            this.$Api.Report.gradeCourseTotal(data)
                .then(res => {
                    // console.log('各年级上课统计:', res.data);
                    this.GradeCourseTotalData = res.data
                })
                .catch(err => {
                    console.log('各年级上课统计err:', err)
                })
        },
        // 本校教师上课排名
        teaRank(startTime, endTime) {
            let data = {
                schoolId: this.$route.query.schoolId,
                startTime,
                endTime,
                termKey: this.allDateTerm
            };
            this.$Api.Report.teaRank(data)
                .then(res => {
                    // console.log('本校教师上课排名:', res);
                    this.teaRankList = res.data;
                })
                .catch(err => {
                    console.log('本校教师上课排名err:', err)
                })
        },
        // 整体应用情况搜索
        searchByAllData() {
            this.gradeCourseTotal(this.allDate[0], this.allDate[1]);
            this.teaRank(this.allDate[0], this.allDate[1]);
            this.countTotal(this.allDate[0], this.allDate[1])
        },
        // 各年级应用比对
        gradeApplicationData() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.allDateTerm
            };
            this.$Api.Report.gradeApplicationData(data)
                .then(res => {
                    // console.log('各年级应用比对:',res);
                    this.gradeApplicationList = res.data;
                })
                .catch(err => {
                    console.log('各年级应用比对err:', err)
                })
        },
        // 同步课达成率分析
        asyncData() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.allDateTerm
            };
            this.$Api.Report.courseRate(data)
                .then(res => {
                    // console.log('同步课达成率分析:', res);
                    this.asyncCourseList = res.data.sort((a, b) => {
                        return b.rate - a.rate
                    });
                })
                .catch(err => {
                    console.log('同步课达成率分析err:', err)
                })

        },
        // 修改学期(整体应用情况)
        termChangeAllDate(value) {
            // 判断选择的学期是否是当前学期
            // 是:学期开始时间-今天
            // 不是:学期开始时间-学期结束时间
            let currentTerm = this.termList[0].value;
            let startTime = this.termList.find(item => item.value == value).startTime;
            let endTime = this.termList.find(item => item.value == value).endTime;
            if (value == currentTerm) {
                // 获取当前日期
                const currentDate = new Date();
                // 获取当前年份
                const currentYear = currentDate.getFullYear();
                // 获取当前月份（注意：月份从0开始，所以需要加1）
                const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
                // 获取当前日期
                const currentDay = currentDate.getDate().toString().padStart(2, '0');
                const today = `${currentYear}-${currentMonth}-${currentDay} 00:00:00`;
                this.allDate = [startTime, today];
                this.date = [startTime, today];
            } else {
                this.allDate = [startTime, endTime];
                this.date = [startTime, endTime];
            }
            this.searchByAllData() // 整体应用情况查询
            // 学校应用统计
            this.gradeApplicationData(); // 最新应用动态-各年级应用对比
            this.asyncData() // 同步课达成率分析
            this.stuAppRank(); // App学生使用情况统计-学生App应用学习排名
            this.watchMost(); // App学生使用情况统计-观看最多的课程Top10
            this.stuAppStudy(); // App学生使用情况统计-各年级学生App学习统计
            this.stuAppTest(); // App学生使用情况统计-各年级学生App测评次数统计
        },
        // 学生App应用学习排名
        stuAppRank() {
            let data = {
                schoolId: this.$route.query.schoolId,
                startTime: this.date[0],
                endTime: this.date[1],
                termKey: this.allDateTerm
            };
            this.$Api.Report.stuAppRank(data)
                .then(res => {
                    // console.log('学生App应用学习排名:', res);
                    this.stuRankList = res.data;
                })
                .catch(err => {
                    console.log('学生App应用学习排名err:', err)
                })
        },
        // 观看最多的课程Top10
        watchMost() {
            let data = {
                schoolId: this.$route.query.schoolId,
                startTime: this.date[0],
                endTime: this.date[1],
                termKey: this.allDateTerm
            };
            this.$Api.Report.watchMost(data)
                .then(res => {
                    this.mostData = res.data;
                })
                .catch(err => {
                    console.log('观看最多的课程Top10err:', err)
                })
        },
        // 学生App学习统计
        stuAppStudy() {
            let data = {
                schoolId: this.$route.query.schoolId,
                startTime: this.date[0],
                endTime: this.date[1],
                termKey: this.allDateTerm
            };
            this.$Api.Report.stuAppStudy(data)
                .then(res => {
                    // console.log('学生App学习统计:', res);
                    this.studyData = res.data;
                })
                .catch(err => {
                    console.log('学生App学习统计err:', err)
                })
        },
        // 学生App测评统计
        stuAppTest() {
            let data = {
                schoolId: this.$route.query.schoolId,
                startTime: this.date[0],
                endTime: this.date[1],
                termKey: this.allDateTerm
            };
            this.$Api.Report.stuAppTest(data)
                .then(res => {
                    // console.log('学生App测评统计:', res);
                    this.testData = res.data;
                })
                .catch(err => {
                    console.log('学生App测评统计err:', err)
                })
        },
        // App学生使用情况搜索
        searchByAppInfo() {
            this.stuAppRank();
            this.watchMost();
            this.stuAppStudy();
            this.stuAppTest()
        },
    },
    mounted() {
        this.getTermList();
    },
};
</script>
<style lang='less' scoped>
@import url("./less/application.less");
</style>