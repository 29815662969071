<!-- 本校上课时间分布折线图 -->
<template>
    <div class="systemYbCourse_line" id="systemYbCourse_line"></div>
</template>
<script>
export default {
    props: {
        termKey: {
            type: Number
        },
        grade: {
            type: [Number, String],
            default: ''
        }
    },
    data() {
        return {}
    },
    watch: {
        termKey(newVal, oldVal) {
            this.ybData()
        },
        grade(newVal, oldVal) {
            this.ybData()
        }
    },
    methods: {
        initYbCharts(xAxisData, seriesData) {
            // console.log('xAxisData', xAxisData);
            // console.log('seriesData', seriesData);
            let legendData = [];
            for (let i = 0; i < seriesData.length; i++) {
                legendData.push(seriesData[i].name)
            }
            // console.log('legendData', legendData);
            this.$echarts.init(document.getElementById("systemYbCourse_line")).dispose();
            let myChart = this.$echarts.init(document.getElementById("systemYbCourse_line"));
            let option = {
                tooltip: {
                    show: true,
                    trigger: 'axis',
                    textStyle: {
                        fontSize: this.graphicRatio(14),
                    },
                },
                grid: {
                    left: 0,
                    right: this.graphicRatio(15),
                    bottom: 0, // 调整底部距离的数值，可以根据需要进行调整
                    containLabel: true
                },
                title: {
                    text: "硬笔体系课",
                    textStyle: {
                        fontSize: this.graphicRatio(14),
                    },
                    top: 0,
                    left: 'center',
                },
                color: ['#A856D3', '#7ECEF4', '#34CAAC', '#78C35E', '#EA68A2', '#FFD040',],
                legend: {
                    data: legendData,
                    // data: ['一年级', '二年级', '三年级', '四年级', '五年级', '六年级'],
                    top: this.graphicRatio(30),
                    textStyle: {
                        color: '#C2C2C2',
                        fontSize:this.graphicRatio(14),
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xAxisData,
                    axisTick: {
                        show: false // 隐藏 X 轴的刻度线
                    },
                    axisLine: { // 设置 X 轴线的样式
                        show: true, // 是否显示 X 轴线
                        lineStyle: {
                            color: '#CECECE', // X 轴线的颜色
                        }
                    },
                    axisLabel: {
                        fontSize: this.graphicRatio(14),
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLine: { // 设置 Y 轴线的样式
                        show: true, // 是否显示 Y 轴线
                        lineStyle: {
                            color: '#CECECE', // Y 轴线的颜色
                        }
                    },
                },
                series: seriesData
            };
            myChart.setOption(option)
        },
        ybData() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termKey,
                grade: this.grade,
                actionType: 1
            };
            this.$Api.Report.systemCourse(data)
                .then(res => {
                    // console.log('硬笔体系课:', res);
                    let seriesData = [];
                    let keys = Object.keys(res.data);
                    res.data[keys[0]].map(item => {
                        let obj = {
                            name: item.name,
                            type: 'line',
                            data: [],
                            itemStyle: {
                                opacity: 0,
                            },
                        }
                        seriesData.push(obj)
                    })

                    keys.map(item => {
                        res.data[item].map(items => {
                            switch (items.name) {
                                case '1年级':
                                    var index = seriesData.findIndex(item => item.name ==
                                        '1年级');
                                    seriesData[index].data.push(items.num)
                                    break;
                                case '2年级':
                                    var index = seriesData.findIndex(item => item.name ==
                                        '2年级');
                                    seriesData[index].data.push(items.num)
                                    break;
                                case '3年级':
                                    var index = seriesData.findIndex(item => item.name ==
                                        '3年级');
                                    seriesData[index].data.push(items.num)
                                    break;
                                case '4年级':
                                    var index = seriesData.findIndex(item => item.name ==
                                        '4年级');
                                    seriesData[index].data.push(items.num)
                                    break;
                                case '5年级':
                                    var index = seriesData.findIndex(item => item.name ==
                                        '5年级');
                                    seriesData[index].data.push(items.num)
                                    break;
                                case '6年级':
                                    var index = seriesData.findIndex(item => item.name ==
                                        '6年级');
                                    seriesData[index].data.push(items.num)
                                    break;
                            }
                        })
                    })
                    // console.log('seriesData:', seriesData)
                    this.initYbCharts(keys, seriesData)
                })
                .catch(err => {
                    // console.log('硬笔体系课err:', err);
                    let keys = ['1周', '2周', '3周', '4周', '5周', '6周', '7周', '8周', '9周', '10周', '11周', '12周', '13周', '14周', '15周', '16周', '17周', '18周', '19周', '20周', '21周', '22周', '23周', '24周', '25周', '26周'];
                    let seriesData = [
                        {
                            data: [],
                            itemStyle: { opcaity: 0 },
                            name: '',
                            type: "line"
                        }
                    ];
                    this.initYbCharts(keys, seriesData)
                })

        }
    },
    mounted() {
    },
};
</script>
<style lang='less' scoped>
.systemYbCourse_line {
    width: 100%;
    height: 100%;
}
</style>