<!-- 体系课-特色课应用情况柱状图 -->
<template>
    <div class="course_box">
        <div v-if="show" class="systemCourse_bar" id="systemCourse_bar"></div>
        <el-empty description="暂无数据" v-else></el-empty>
    </div>
</template>
<script>
export default {
    props: {
        termKey: {
            type: Number
        },
        grade: {
            type: [Number, String],
            default: ''
        }
    },
    data() {
        return {
            show: true
        }
    },
    watch: {
        termKey(newVal, oldVal) {
            this.systemData()
        },
        grade(newVal, oldVal) {
            console.log('newVal:', newVal)
            this.systemData(newVal)
        }
    },
    methods: {
        initChart(xAxisData, seriesData, type) {
            // console.log('type:',type)
            this.$echarts.init(document.getElementById("systemCourse_bar")).dispose();
            let myChart = this.$echarts.init(document.getElementById("systemCourse_bar"));
            let option = {
                tooltip: {
                    show: true,
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    },
                },
                dataZoom: type ? {
                    start: 0,
                    end: 100,
                    type: 'inside',
                    zoomLock: true // 禁用鼠标缩放
                } : '',
                grid: {
                    left: 0,
                    right: 0,
                    bottom: this.graphicRatio(40), // 调整底部距离的数值，可以根据需要进行调整
                    containLabel: true
                },
                color: [{
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0,
                        color: 'rgba(167, 198, 255, 1)' // 0% 处的颜色
                    }, {
                        offset: 1,
                        color: 'rgba(203, 156, 252, 1)' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                }, {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0,
                        color: 'rgba(255, 217, 167, 1)' // 0% 处的颜色
                    }, {
                        offset: 1,
                        color: 'rgba(221, 223, 41, 1)' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },],
                legend: {
                    show: true,
                    bottom: 0,
                    itemWidth: this.graphicRatio(22),
                    itemHeight: this.graphicRatio(14),
                    textStyle: {
                        color: '#C2C2C2',
                        fontSize: this.graphicRatio(12),
                    }
                },
                title: {
                    text: "特色课应用情况",
                    textStyle: {
                        fontSize: this.graphicRatio(14),
                    },
                    top: 0,
                    left: 'center',
                },
                xAxis: {
                    type: 'category',
                    data: xAxisData,
                    axisTick: {
                        show: false // 隐藏 X 轴的刻度线
                    },
                    axisLine: { // 设置 X 轴线的样式
                        show: true, // 是否显示 X 轴线
                        lineStyle: {
                            color: '#CECECE', // X 轴线的颜色
                        }
                    },
                    axisLabel: {
                        fontSize: this.graphicRatio(14),
                    },
                },
                yAxis: {
                    type: 'value',
                    splitLine: {
                        show: false // 不显示横向网格线
                    },
                    axisLine: { // 设置 Y 轴线的样式
                        show: true, // 是否显示 Y 轴线
                        lineStyle: {
                            color: '#CECECE', // Y 轴线的颜色
                        }
                    },
                    axisLabel: {
                        fontSize: this.graphicRatio(14),
                    },
                },
                series: seriesData
            };
            myChart.setOption(option)
        },
        systemData(type) {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termKey,
                grade: this.grade
            };
            this.$Api.Report.featuredCourse(data)
                .then(res => {
                    // console.log('特色课应用情况:', res);
                    if (res.data.hardPen.length == 0 && res.data.softPen.length == 0) {
                        this.show = false
                        return
                    } else {
                        this.show = true;
                        const allTermIndices = [
                            ...new Set([
                                ...res.data.softPen.map(obj => obj.name),
                                ...res.data.hardPen.map(obj => obj.name)
                            ])
                        ].sort((a, b) => a - b);
                        // console.log('合并后的allTermIndices:', allTermIndices);
                        // 遍历原始数据，更新不存在的 termIndex
                        let hardPen = allTermIndices.map(item => ({
                            "termIndex": item,
                            "num": res.data.hardPen.find(obj => obj.name === item)?.num || 0
                        }));
                        let softPen = allTermIndices.map(item => ({
                            "termIndex": item,
                            "num": res.data.softPen.find(obj => obj.name === item)?.num || 0
                        }))
                        let rbData = {
                            name: '软笔体系课数量',
                            data: [],
                            type: 'bar',
                            barWidth: type ? 20 : 32,
                            itemStyle: {
                                borderRadius: [5, 5, 0, 0]
                            },
                            label: {
                                show: true,
                                position: 'top',
                                color: "#CECECE"
                            }
                        }
                        let ybData = {
                            name: '硬笔体系课数量',
                            data: [],
                            type: 'bar',
                            barWidth: type ? this.graphicRatio(20) : this.graphicRatio(32),
                            itemStyle: {
                                borderRadius: [this.graphicRatio(5), this.graphicRatio(5), 0, 0]
                            },
                            label: {
                                show: true,
                                position: 'top',
                                color: "#CECECE",
                                fontSize: this.graphicRatio(14),
                            }
                        }
                        let xAxisData = [];
                        softPen.map(item => {
                            rbData.data.push(item.num);
                            xAxisData.push(item.termIndex)
                        });
                        hardPen.map(item => {
                            ybData.data.push(item.num)
                        })
                        let seriesData = [ybData, rbData];
                        this.$nextTick(() => {
                            this.initChart(xAxisData, seriesData, type)
                        })
                    }

                })
                .catch(err => {
                    console.log('特色课应用情况err:', err);
                    this.show = false
                })
        }
    },
    mounted() {

    },
};
</script>
<style lang='less' scoped>
.course_box {
    width: 100%;
    height: 100%;

    .systemCourse_bar {
        width: 100%;
        height: 100%;
    }
}
</style>