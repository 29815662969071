<!-- 同步课达成率分析 -->
<template>
    <div class="asyncCourse_bar" id="asyncCourse_bar"></div>
</template>
<script>
export default {
    props: {
        termKey: {
            type: Number
        }
    },
    data() {
        return {}
    },
    watch: {
        termKey(newVal, oldVal) {
            this.asyncData()
        }
    },
    methods: {
        initChart(xAxisData, seriesData) {
            this.$echarts.init(document.getElementById("asyncCourse_bar")).dispose();
            let myChart = this.$echarts.init(document.getElementById("asyncCourse_bar"));
            let option = {
                tooltip: {
                    show: true,
                },
                grid: {
                    left: 0,
                    right: 50
                },
                // legend: {
                //     top: 'bottom'
                // },
                dataZoom: {
                    start: 0,
                    end: 30,
                    type: 'inside',
                    zoomLock: true // 禁用鼠标缩放
                },
                color: [{
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0,
                        color: 'rgba(117, 186, 87, 1)' // 0% 处的颜色
                    }, {
                        offset: 1,
                        color: 'rgba(255, 234, 167, 1)' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                }, {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0,
                        color: 'rgba(254, 165, 188, 1)' // 0% 处的颜色
                    }, {
                        offset: 1,
                        color: 'rgba(255, 180, 245, 1)' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                }, {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0,
                        color: 'rgba(100, 188, 255, 1)' // 0% 处的颜色
                    }, {
                        offset: 1,
                        color: 'rgba(98, 238, 217, 1)' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                }, {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0,
                        color: 'rgba(254, 104, 143, 1)' // 0% 处的颜色
                    }, {
                        offset: 1,
                        color: 'rgba(250, 195, 141, 1)' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                }, {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0,
                        color: 'rgba(78, 218, 213, 1)' // 0% 处的颜色
                    }, {
                        offset: 1,
                        color: 'rgba(144, 231, 148, 1)' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                }, {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0,
                        color: 'rgba(221, 223, 41, 1)' // 0% 处的颜色
                    }, {
                        offset: 1,
                        color: 'rgba(231, 177, 144, 1)' // 100% 处的颜色
                    }],
                    global: false // 缺省为 false
                },],
                xAxis: {
                    type: 'category',
                    data: xAxisData,
                    offset: 40,
                    axisTick: {
                        show: false // 隐藏 X 轴的刻度线
                    },
                    axisLine: { // 设置 X 轴线的样式
                        show: true, // 是否显示 X 轴线
                        lineStyle: {
                            color: '#CECECE', // X 轴线的颜色
                        }
                    },
                },
                yAxis: {
                    type: 'value',
                    splitLine: {
                        show: false // 不显示横向网格线
                    },
                },
                series: seriesData
            };
            myChart.setOption(option)
        },
        asyncData() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termKey
            };
            this.$Api.Report.courseRate(data)
                .then(res => {
                    // console.log('同步课达成率分析:', res);
                    // 获取所有年级的班级列表
                    const allClasses = Object.values(res.data)
                        .flatMap(classes => classes.map(classInfo => classInfo.className))
                        .filter((value, index, self) => self.indexOf(value) === index);
                    // 创建结果对象
                    const resultData = {};
                    // 遍历所有年级
                    for (const grade in res.data) {
                        // 初始化当前年级的结果数组
                        resultData[grade] = [];
                        // 获取当前年级的班级列表
                        const currentClasses = res.data[grade].map(classInfo => classInfo.className);
                        // 遍历所有班级
                        for (const className of allClasses) {
                            // 查找当前班级在当前年级的数据中的索引
                            const classIndex = currentClasses.indexOf(className);
                            // 如果班级存在，则将其添加到结果数组中，否则添加0的rate
                            if (classIndex !== -1) {
                                resultData[grade].push(res.data[grade][classIndex]);
                            } else {
                                resultData[grade].push({
                                    rate: null,
                                    className: className
                                });
                            }
                        }
                        // 根据班级名称升序排列结果数组
                        // resultData[grade].sort((a, b) => {
                        //     // 提取班级编号并将其转换为数字，然后比较
                        //     const classNumberA = parseInt(a.className.replace(/\D/g, ""), 10);
                        //     const classNumberB = parseInt(b.className.replace(/\D/g, ""), 10);
                        //     return classNumberA - classNumberB;
                        // });
                    }
                    // console.log('resultData----:', resultData);

                    // 生成的对象数组
                    const seriesData = [];
                    // 循环处理每个年级的数据
                    for (const grade in resultData) {
                        if (resultData.hasOwnProperty(grade)) {
                            const gradeData = resultData[grade];
                            // 对每个班级进行处理
                            gradeData.forEach((classInfo) => {
                                const existingClassObject = seriesData.find(obj => obj.label.formatter === classInfo.className);
                                // 如果已经存在该班级的对象，则将 rate 添加到对应的 data 数组中
                                if (existingClassObject) {
                                    existingClassObject.data.push(classInfo.rate);
                                } else {
                                    // 如果不存在该班级的对象，则创建新的对象
                                    const newClassObject = {
                                        data: [classInfo.rate],
                                        name:classInfo.className,
                                        type: 'bar',
                                        barWidth: 17,
                                        barGap: "100%",
                                        // barCategoryGap: "200%",
                                        label: {
                                            color: '#C2C2C2',
                                            show: true,
                                            position: 'bottom',
                                            formatter: classInfo.className,
                                            rotate: 45,
                                            distance: 20
                                        },
                                        itemStyle: {
                                            borderRadius: [3, 3, 0, 0]
                                        },
                                    };

                                    // 将新的对象添加到结果数组中
                                    seriesData.push(newClassObject);
                                }
                            });
                        }
                    }
                    // console.log('seriesData:', seriesData)
                    let keys = Object.keys(resultData);
                    // console.log('keys:', keys)
                    this.initChart(keys, seriesData);
                })
                .catch(err => {
                    console.log('同步课达成率分析err:', err)
                })

        }
    },
    mounted() { },
};
</script>
<style lang='less' scoped>
.asyncCourse_bar {
    width: 100%;
    height: 100%;
}
</style>