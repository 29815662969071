<!-- 各年级上课统计柱状图 -->
<template>
    <div class="box">
        <!-- 整体应用情况-各年级上课统计柱状图 -->
        <div class="gradeCourseTotal" id="gradeCourseTotal" v-if="name == 'GradeCourseTotal'">1</div>
        <!-- 最新应用动态-最近15天应用数据柱状图 -->
        <div class="applicationData" id="applicationData" v-if="name == 'stateInfo'">2</div>
    </div>
</template>
<script>
export default {
    props: ['name', 'GradeCourseTotalData'],
    // props: {
    //     name: {
    //         type: String,
    //         require: true
    //     },
    //     GradeCourseTotalData: {
    //         type: Array
    //     }
    // },
    data() {
        return {}
    },
    // watch: {
    //     GradeCourseTotalData(newVal, oldVal) {
    //         let xAxisData = [];
    //         let seriesData = [];
    //         newVal.map(item => {
    //             xAxisData.push(item.name);
    //             seriesData.push(item.num)
    //         })
    //         this.initGradeCourseTotalCharts(xAxisData, seriesData)
    //     }
    // },
    methods: {
        processingData() {
            if (this.name && this.GradeCourseTotalData.length != 0) {
                let xAxisData = [];
                let seriesData = [];
                this.GradeCourseTotalData.map(item => {
                    xAxisData.push(item.name);
                    seriesData.push(item.num)
                })
                this.initGradeCourseTotalCharts(xAxisData, seriesData)
                // this.name == 'GradeCourseTotal' ? this.initGradeCourseTotalCharts(xAxisData, seriesData) : this.applicationData(xAxisData, seriesData);
            }
        },
        initGradeCourseTotalCharts(xAxisData, seriesData) {
            this.$echarts.init(document.getElementById("gradeCourseTotal")).dispose();
            let myChart = this.$echarts.init(document.getElementById("gradeCourseTotal"));
            let option = {
                tooltip: {
                    show: true,
                    textStyle: {
                        fontSize: this.graphicRatio(14),
                    },
                },
                grid: {
                    left: 0,
                    bottom: 0, // 调整底部距离的数值，可以根据需要进行调整
                    containLabel: true
                },
                title: {
                    text: "各年级上课统计",
                    textStyle: {
                        fontSize: this.graphicRatio(14),
                    },
                    top: 0,
                    left: 'center',

                },
                xAxis: {
                    type: 'category',
                    data: xAxisData,
                    axisTick: {
                        show: false // 隐藏 X 轴的刻度线
                    },
                    axisLine: { // 设置 X 轴线的样式
                        show: true, // 是否显示 X 轴线
                        lineStyle: {
                            color: '#CECECE', // X 轴线的颜色
                        }
                    },
                    axisLabel: {
                        fontSize: this.graphicRatio(14),
                    }
                },
                yAxis: {
                    type: 'value',
                    splitLine: {
                        show: false // 不显示横向网格线
                    },
                    axisLine: { // 设置 Y 轴线的样式
                        show: true, // 是否显示 Y 轴线
                        lineStyle: {
                            color: '#CECECE', // Y 轴线的颜色
                        }
                    },
                    axisLabel: {
                        fontSize: this.graphicRatio(14),
                    }
                },
                series: [{
                    data: seriesData,
                    type: 'bar',
                    barWidth: this.graphicRatio(32),
                    itemStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0,
                                color: 'rgba(229, 124, 12, 1)' // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: 'rgba(243, 178, 107, 1)' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        },
                        borderRadius: [this.graphicRatio(5), this.graphicRatio(5), 0, 0]
                    },
                    label: {
                        show: true,
                        position: 'top',
                        color: "#CECECE",
                        fontSize: this.graphicRatio(14),
                    }
                }]
            };
            myChart.setOption(option)
        },
        initApplicationDataCharts(xAxisData, seriesData) {
            this.$echarts.init(document.getElementById("applicationData")).dispose();
            let myChart = this.$echarts.init(document.getElementById("applicationData"));
            let option = {
                tooltip: {
                    show: true,
                    textStyle: {
                        fontSize: this.graphicRatio(14),
                    },
                },
                grid: {
                    left: 0,
                    right: 0,
                    bottom: 0, // 调整底部距离的数值，可以根据需要进行调整
                    containLabel: true
                },
                title: {
                    text: "最近15天应用数据",
                    textStyle: {
                        fontSize:this.graphicRatio(14),
                    },
                    top: 0,
                    left: 'center',

                },
                xAxis: {
                    type: 'category',
                    data: xAxisData,
                    axisTick: {
                        show: false // 隐藏 X 轴的刻度线
                    },
                    axisLine: { // 设置 X 轴线的样式
                        show: true, // 是否显示 X 轴线
                        lineStyle: {
                            color: '#CECECE', // X 轴线的颜色
                        }
                    },
                    axisLabel: {
                        fontSize: this.graphicRatio(14),
                    }
                },
                yAxis: {
                    type: 'value',
                    splitLine: {
                        show: false // 不显示横向网格线
                    },
                    axisLine: { // 设置 Y 轴线的样式
                        show: true, // 是否显示 Y 轴线
                        lineStyle: {
                            color: '#CECECE', // Y 轴线的颜色
                        }
                    },
                    axisLabel: {
                        fontSize: this.graphicRatio(14),
                    }
                },
                series: [{
                    data: seriesData,
                    type: 'bar',
                    barWidth: this.graphicRatio(32),
                    itemStyle: {
                        color: {
                            type: 'linear',
                            x: 0,
                            y: 0,
                            x2: 0,
                            y2: 1,
                            colorStops: [{
                                offset: 0,
                                color: 'rgba(214, 215, 97, 1)' // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: 'rgba(231, 165, 130, 1)' // 100% 处的颜色
                            }],
                            global: false // 缺省为 false
                        },
                        borderRadius: [this.graphicRatio(5), this.graphicRatio(5),  0, 0]
                    },
                    label: {
                        show: true,
                        position: 'top',
                        color: "#CECECE",
                        fontSize: this.graphicRatio(14),
                    }
                }]
            };
            myChart.setOption(option)
        },
        // 最近15天数据
        applicationData() {

            // 获取当前日期
            var currentDate = new Date();
            // 创建一个数组来存储最近 15 天的日期
            var recentDates = [];
            // 循环生成最近 15 天的日期并添加到数组中
            for (var i = 0; i < 15; i++) {
                var date = new Date(currentDate);
                date.setDate(currentDate.getDate() - i);
                let obj = {
                    day: date.getDate(),
                    num: 0
                }
                recentDates.unshift(obj);
            }
            let data = {
                schoolId: this.$route.query.schoolId
            };
            this.$Api.Report.applicationData(data)
                .then(res => {
                    // console.log('最近15天数据:', res);
                    res.data.map(item => {
                        for (var i = 0; i < recentDates.length; i++) {
                            if (item.date == recentDates[i].day) {
                                recentDates[i].num = item.num;
                                break
                            }
                        }
                    })
                    // console.log("处理后最近15天的日期:", recentDates);
                    let xAxisData = [];
                    let seriesData = [];
                    recentDates.map(item => {
                        xAxisData.push(item.day + '日');
                        seriesData.push(item.num)
                    })
                    // console.log("处理后最近15天的日期:", xAxisData, seriesData);
                    this.initApplicationDataCharts(xAxisData, seriesData)
                })
                .catch(err => {
                    console.log('最近5天数据err:', err)
                })
        }
    },
    mounted() {
        // this.processingData();
        if (this.name == 'GradeCourseTotal') {
            this.processingData();
            // this.initGradeCourseTotalCharts();
        } else {
            this.applicationData()
        }


    },
};
</script>
<style lang='less' scoped>
.box {
    width: 100%;
    height: 100%;

    .gradeCourseTotal,
    .applicationData {
        width: 100%;
        height: 100%;

        .custom-tooltip {
            position: relative;
            background-color: rgba(0, 0, 0, 0.7);
            border-radius: 5px;
            padding: 10px;
        }

        .custom-tooltip::before {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -10px;
            /* 控制三角形的位置 */
            border-width: 10px;
            border-style: solid;
            border-color: transparent transparent rgba(0, 0, 0, 0.7) transparent;
        }

        .tooltip-content {
            color: #fff;
            font-size: 14px;
        }
    }
}
</style>