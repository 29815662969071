<!-- 本校上课时间分布折线图 -->
<template>
    <div class="courseTime" id="courseTime"></div>
</template>
<script>
export default {
    props: {
        termKey: {
            type: Number
        }
    },
    data() {
        return {}
    },
    watch: {
        termKey(newVal, oldVal) {
            this.courseTimeData()
        }
    },
    methods: {
        initCourseTimeCharts(seriesData) {
            this.$echarts.init(document.getElementById("courseTime")).dispose();
            let myChart = this.$echarts.init(document.getElementById("courseTime"));
            let option = {
                tooltip: {
                    show: true,
                    trigger: 'axis',
                    textStyle: {
                        fontSize: this.graphicRatio(12),
                    },
                },
                grid: {
                    left: 0,
                    right: this.graphicRatio(15),
                    bottom: 0, // 调整底部距离的数值，可以根据需要进行调整
                    containLabel: true
                },
                title: {
                    text: "本校上课时间分布",
                    textStyle: {
                        fontSize: this.graphicRatio(14),
                    },
                    top: 0,
                    left: 'center',
                },
                color: ['#A856D3', '#7ECEF4', '#34CAAC', '#78C35E', '#EA68A2', '#FFD040'],
                legend: {
                    show: true,
                    data: ['1年级', '2年级', '3年级', '4年级', '5年级', '6年级'],
                    top: this.graphicRatio(30),
                    textStyle: {
                        color: '#C2C2C2',
                        fontSize: this.graphicRatio(14),
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: ['0时', '1时', '2时', '3时', '4时', '5时', '6时', '7时', '8时', '9时', '10时', '11时', '12时',
                        '13时', '14时', '15时', '16时', '17时', '18时', '19时', '20时', '21时', '22时', '23时'
                    ],
                    splitLine: {
                        show: true
                    },
                    axisTick: {
                        show: false // 隐藏 X 轴的刻度线
                    },
                    axisLine: { // 设置 X 轴线的样式
                        show: true, // 是否显示 X 轴线
                        lineStyle: {
                            color: '#CECECE', // X 轴线的颜色
                        }
                    },
                    axisLabel: {
                        fontSize: this.graphicRatio(14),
                    },
                },
                yAxis: {
                    type: 'value',
                    axisLine: { // 设置 Y 轴线的样式
                        show: true, // 是否显示 Y 轴线
                        lineStyle: {
                            color: '#CECECE', // Y 轴线的颜色
                        }
                    },
                    axisLabel: {
                        fontSize: this.graphicRatio(14),
                    },
                },
                series: seriesData
            };
            myChart.setOption(option)
        },
        courseTimeData() {
            let data = {
                schoolId: this.$route.query.schoolId,
                termKey: this.termKey
            };
            this.$Api.Report.courseTimeData(data)
                .then(res => {
                    // console.log('本校上课时间分布折线图:', res);
                    const keys = Object.keys(res.data);
                    const seriesData = [];
                    keys.map(item => {
                        let obj = {
                            name: `${item}年级`,
                            type: 'line',
                            // stack: 'Total',
                            data: res.data[item]
                        };
                        seriesData.push(obj)
                    })
                    this.initCourseTimeCharts(seriesData);
                })
                .catch(err => {
                    // console.log('本校上课时间分布err:', err)
                })
        }
    },
    mounted() {


    },
};
</script>
<style lang='less' scoped>
.courseTime {
    width: 100%;
    height: 100%;
}
</style>